import CommunicationService from '../../Utils/CommunicationService';

export interface ISingleDO {
  externalId: string;
  id: string;
  key: string;
  name: string;
  outsourceCompany: string;
  outsourceEnable: boolean;
  planType: string;
  scheduleIntegration?: {
    integration?: {
      locationId: number,
      subdomain: string
    }
  }
}

export const getAvailableOffices = async () => {
  const result = await CommunicationService.getInstance().secureCall<ISingleDO[]>({
    type: 'GET',
    path: '/api/dentaloffice/user/all'
  });
  if (result.body) {
    return result.body;
  }
};

export const getDentalOfficeInformation = async (officeId: string) => {
  const result = await CommunicationService.getInstance().secureCall<ISingleDO>({
    type: 'GET',
    path: `/api/dentaloffice/${officeId}`
  });
  if (result.body) {
    return result.body;
  }
};
