import { TAdaCode, TCategory, TSubcategory } from "./interfaces.v3";

/** 
 * Represents different options for the availability of
 * history information. It defines the following options:
 * - `ALL`: Indicates that all history information is provided.
 * - `NONE`: Indicates that no history information is provided.
 * - `PARTIAL`: Indicates that only partial history information is provided.
 * - `INCONSISTENT`: Indicates that the provided history information is inconsistent. 
 */
export enum EProvidesHistory {
  ALL = 'all',
  NONE = 'none',
  PARTIAL = 'partial',
  INCONSISTENT = 'inconsistent',
}

/**
 * Specifies the unit of measurement for the history time range.
 * - `LAST_VISITS`: A specified number of past visits are included.
 * - `MONTHS`: History is provided for a set number of months.
 * - `NEXT_ELIGIBLE`: Indicates when a procedure becomes eligible again.
 * - `ALL`: The entire available history is provided.
 */
export enum EHistoryTimeRangeUnit {
  LAST_VISITS = 'lastVisits',
  MONTHS = 'months',
  NEXT_ELIGIBLE = 'nextEligible',
  ALL = 'all',
}

/**
 * Defines a time range where a numeric amount is required.
 * Used when specifying a limited history, such as the last X visits or months.
 */
export type IHistoryPartialTimeRange = {
  unit:
    | EHistoryTimeRangeUnit.LAST_VISITS
    | EHistoryTimeRangeUnit.MONTHS
    | EHistoryTimeRangeUnit.NEXT_ELIGIBLE;
  amount: number;
};
/**
 * Defines a time range where no numeric amount is required.
 * This applies when the full history is available.
 */
export type IHistoryEntireTimeRange = {
  unit: EHistoryTimeRangeUnit.ALL;
  amount?: never;
};

/**
 * Represents different types of history time ranges.
 * It can either be a **partial** range (with an amount) or an **entire** range.
 */
export type THistoryTimeRange =
  | IHistoryPartialTimeRange
  | IHistoryEntireTimeRange;

/**
 * Represents frequency conflicts between procedure codes.
 * Example:
 * ```
 * [
 *   ['D1110', 'D4910'],  // D1110 conflicts with D4910
 *   ['D2950', 'D2952']   // D2950 conflicts with D2952
 * ]
 * ```
 */
export type TProcedureCodesFrequencyConflicts = Array<Array<TAdaCode>>;

/**
 * Defines the core structure of a history capability.
 * This includes:
 * - `providedCategories`: A list of categories available in history.
 * - `providedSubcategories`: A list of subcategories available.
 * - `timeRange`: Specifies how much history is provided.
 * - `disclaimers`: Any warnings or notes about the history data.
 */
export interface IHistoryCapabilityBase {
  providedCategories: TCategory[];
  providedSubcategories: TSubcategory[];
  timeRange: THistoryTimeRange;
  disclaimers: string[];
}

/**
 * Represents cases where no detailed history information is available.
 * This applies when:
 * - `NONE`: No history data is provided by the carrier.
 * - `INCONSISTENT`: The carrier's history data is unreliable, conflicting, or its availability is unknown.
 * - `ALL`: The carrier provides full history, making further breakdown unnecessary.
 */
export interface IHistoryCapabilityNoData {
  providesHistory:
    | EProvidesHistory.NONE
    | EProvidesHistory.INCONSISTENT
    | EProvidesHistory.ALL;
  providesProcedureCodes?: never;
  providedProcedureCodes?: never;
  procedureCodesFrequencyConflicts?: never;
}

/**
 * Represents history capability **without** procedure codes.
 * - `providesHistory`: Must be `PARTIAL` or `ALL`.
 * - `providesProcedureCodes`: Can be `NONE`, `ALL`, or `INCONSISTENT`.
 */
export type THistoryCapabilityWithoutProcedureCodesWithAvailability =
  IHistoryCapabilityBase & {
    providesHistory: EProvidesHistory.PARTIAL;
    providesProcedureCodes:
      | EProvidesHistory.NONE
      | EProvidesHistory.ALL
      | EProvidesHistory.INCONSISTENT;
    providedProcedureCodes?: never;
    procedureCodesFrequencyConflicts?: never;
  };

/**
 * Represents history capability **with** procedure codes.
 * - `providesHistory`: Must be `PARTIAL`.
 * - `providesProcedureCodes`: Must be `PARTIAL`.
 * - `providedProcedureCodes`: Contains a list of valid procedure codes.
 * - `procedureCodesFrequencyConflicts`: Contains any frequency conflicts between procedure codes.
 */
export type THistoryCapabilityWithProcedureCodesWithAvailability =
  IHistoryCapabilityBase & {
    providesHistory: EProvidesHistory.PARTIAL;
    providesProcedureCodes: EProvidesHistory.PARTIAL;
    providedProcedureCodes: TAdaCode[];
    procedureCodesFrequencyConflicts: TProcedureCodesFrequencyConflicts;
  };

/**
 * Represents the full structure of a carrier's history capability.
 * - If `providesHistory` is `NONE` or `INCONSISTENT`, no detailed history is available.
 * - Otherwise, it is either a **history capability with or without procedure codes**.
 */
export type THistoryCapability =
  | IHistoryCapabilityNoData
  | THistoryCapabilityWithoutProcedureCodesWithAvailability
  | THistoryCapabilityWithProcedureCodesWithAvailability;

/**
 * Represents a carrier's eligibility capability for a single channel.
 *
 * Includes:
 * - `history`: Detailed history information as defined in `THistoryCapability`.
 * - `planStatus`: Indicates whether the carrier provides plan status.
 *   - `true` → The carrier provides plan status.
 *   - `false` → The carrier does **not** provide plan status.
 *   - `undefined` → Unknown.
 * - `insuranceRemaining`: Indicates whether the carrier provides insurance remaining information.
 *   - `true` → The carrier provides this data.
 *   - `false` → The carrier does **not** provide this data.
 *   - `undefined` → Unknown.
 */
export interface ICarrierEligibilityCapability {
  history?: THistoryCapability;
  planStatus?: boolean;
  insuranceRemaining?: boolean;
}

/**
 * Defines the clearinghouse eligibility capabilities.
 * Clearinghouse data can be available from multiple sources.
 * - `onederful`: Eligibility data from the Onederful clearinghouse.
 * - `onederfulStandardized`: **(CURRENTLY NOT SUPPORTED)** Standardized eligibility data from Onederful.
 * - `changeHealthcare`: **(CURRENTLY NOT SUPPORTED)** Eligibility data from Change Healthcare.
 */
export interface IClearinghousesEligibilityCapabilities {
  onederful?: ICarrierEligibilityCapability;
  onederfulStandardized?: ICarrierEligibilityCapability;
  changeHealthcare?: ICarrierEligibilityCapability;
}

/**
 * Represents a carrier's full eligibility capabilities.
 * It contains:
 * - `portal`: Eligibility data from the carrier's portal.
 * - `clearinghouse`: Eligibility data from various clearinghouses.
 */
export interface ICarrierCapabilities {
  portal: ICarrierEligibilityCapability;
  clearinghouse: IClearinghousesEligibilityCapabilities;
}
