import React, { useEffect, useState } from 'react';
import {Helmet} from 'react-helmet';
import './App.style.css';
import { Theme } from './Theme/ThemeProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppRouter } from './Routes/AppRouter';
import { AppRouterProvider } from './Contexts/AppRouterContext';
import { BizonStore } from './Contexts/BizonStore';
import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { getConnectionData, IConnectionCredentials } from './Utils/GetConnectionCredentials';
import { FrameLoading } from './Components/FrameLoading';
import OrganizationPage, { ORG_ID_KEY } from './Screens/Public/OrganizationPage';
import { BugReportOverride } from './Utils/BugReport';
import { BizonStoreProvider } from './Contexts/BizonStoreContext';
import {getEnvValue} from './Utils/FrontEnvs';
import { StripeProvider } from './Contexts/StripeContext';
import { Interface } from 'verrific-plus-schema';
import MaintananceLockPage from './Screens/Public/MaintananceLockPage';
import { DevTool } from './Components/DevTool';
import { SessionProvider } from './Contexts/SessionContext';

const LOCAL_SKIP_MT_LOCK = 'LOCAL_SKIP_MT_LOCK';

const SERVICE_TYPE = {
  DENTAL_OFFICE: 'DENTAL_OFFICE',
  IV_COMPANY: 'IV_COMPANY',
  DSO: 'DSO'
};

// Change this flag to force VeriSmart 
// dental office user login on local 
// development env

const DO_DOMAIN_PART = ['verismart', 'verismartai'];
const ENVS = ['sdbx', 'qa', 'alpha', 'beta'];
const DSO_DOMAIN_PART = ['.sdbx.verismartai.com', '.qa.verismartai.com', '.alpha.verismartai.com', '.beta.verismartai.com', '.verismartai.com'];

type TInitialRenderState = 'loading' | 'get_org' | 'ready' | 'lock';
BugReportOverride.getInstance();

function App() {
  const [ initialised, setInitialised ] = useState(false);
  const [ credentials, setCredentials ] = useState<IConnectionCredentials>();
  const [ initialState, setInitialState ] = useState<TInitialRenderState>('loading');
  const [ organizationId, setOrganizationId ] = useState<null | string>(null);
  const [ auth0Params, setAuth0Params] = useState<Auth0ProviderOptions>(
    {
      domain: '',
      clientId: '',
      authorizationParams: {
        redirectUri: `${window.location.origin}/handleLogin`,
      },
      cacheLocation: 'localstorage'
    }
  );

  const getConnectionCredentials = async () => {
    const result = await getConnectionData();
    if (result){
      const dom = result?.domain?.split('/');
      const newCredentials: IConnectionCredentials = {
        clientId: result?.clientId,
        domain: dom?.[2]
      };
      setCredentials(newCredentials);
      setAuth0Params({
        ...auth0Params,
        domain: newCredentials?.domain || '',
        clientId: newCredentials?.clientId || ''
      });
    }
  };

  const getOrganizationId = async () => {
    const orgId = localStorage.getItem(ORG_ID_KEY);
    localStorage.removeItem(ORG_ID_KEY);
    if (orgId) {
      setOrganizationId(orgId);
      setAuth0Params({
        ...auth0Params,
        domain: credentials?.domain || '',
        clientId: credentials?.clientId || '',
        authorizationParams: {
          organization: orgId
        }
      });
    } else {
      setInitialState('get_org');
    }
  };

  const checkServiceType = () => {
    if (location.hostname.toLowerCase().split('.').some(el => DSO_DOMAIN_PART.includes(el)) && 
      !ENVS.some(el => location.hostname.toLowerCase().startsWith(el))
    ) {
      return SERVICE_TYPE.DSO;
    } else if (location.hostname.toLowerCase().split('.').some(el => DO_DOMAIN_PART.includes(el))) {
      return SERVICE_TYPE.DENTAL_OFFICE;
    }
    return SERVICE_TYPE.IV_COMPANY;
  };

  const init = async () => {
    BizonStore.getInstance();
    setInitialised(true);
    await getConnectionCredentials();
  };

  const setupServiceType = async () => {
    if (credentials?.clientId && credentials?.domain) {
      await getOrganizationId();
    }
  };

  useEffect(() => {
    setupServiceType();
  }, [credentials]);

  const handleCredentials = async () => {
    if (credentials?.clientId && credentials?.domain && organizationId) {
      setAuth0Params({
        ...auth0Params,
        domain: credentials?.domain || '', 
        clientId: credentials?.clientId || '',
        authorizationParams: {
          organization: organizationId
        }
        // organization: organizationId
      });
      
      const serviceType = checkServiceType();
      if (serviceType === SERVICE_TYPE.IV_COMPANY) {
        setInitialState('ready');
      } else {
        const isMaintatnanceLock = await getEnvValue(Interface.FlagName.MAINTANANCE_LOCK);
        const isAdminTesting = window.localStorage.getItem(LOCAL_SKIP_MT_LOCK) === 'true';
        if (isMaintatnanceLock && !isAdminTesting) {
          setInitialState('lock');
        } else {
          setInitialState('ready');
        }
      }
      setInitialState('ready');
    }
  };

  useEffect(() => {
    handleCredentials();
  }, [credentials, organizationId]);
  
  useEffect(() => {
    new DevTool();
    init();
  }, []);

  return (
    <FrameLoading alternateLoading zIndex={2000} overAll isLoading={initialState === 'loading'}>
      <>
        {initialState === 'get_org' &&
          (
            <OrganizationPage setOrganizationId={setOrganizationId}/>
          )
        }
        {initialState === 'lock' &&
          (
            <MaintananceLockPage/>
          )
        }
        {initialState === 'ready' &&
          (
            <Auth0Provider
              {...auth0Params}
            >
              <div className="App">
                <Helmet>
                  <title>VeriSmart</title>
                  <meta name="viewport" content={'width=1280, initial-scale=1, user-scalable=no'}></meta>
                </Helmet>
                {initialised && (
                  <Router basename="/">
                    <Theme>
                      <SessionProvider>
                        <AppRouterProvider>
                          <StripeProvider>
                            <BizonStoreProvider>
                              <AppRouter />
                            </BizonStoreProvider>
                          </StripeProvider>
                        </AppRouterProvider>
                      </SessionProvider>
                    </Theme>
                  </Router>
                )}
              </div>    
            </Auth0Provider>
          )
        }
      </>
    </FrameLoading>
  );
}

export default App;
