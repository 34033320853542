import { string } from "yup";

const combo = <T>(a: T[], min?: number, max?: number): T[][] => {
  min = min || 1;
  max = Math.min(max || a.length, a.length);
  const fn = (n: number, src: T[], got: T[], all: T[][]) => {
    if (n == 0) {
      if (got.length > 0) {
        all[all.length] = got;
      }
      return;
    }
    for (let j = 0; j < src.length; j++) {
      fn(n - 1, src.slice(j + 1), got.concat([src[j]]), all);
    }
    return;
  };
  const all: any[] = [];
  for (let i = min; i <= max; i++) {
    fn(i, a, [], all);
  }

  return all;
};

export type IAdaSimpleObjectCustomExceptionScored = Partial<IAdaSimpleObjectScored>;

export interface ICorelatedWithCommon { 
  code: TAdaCode;
  /** How codes correlate */
  how: TCorelationHow;
  apply: boolean | null;
  data?: string[];
};

export interface ICorelatedWithWaitingPeriod { 
  code: TAdaCode;
  how: CorrelationEnum.WAITING_PERIOD;
  apply: boolean | null;
  data: IWaitingPeriod;
};

export type ICorelatedWith = ICorelatedWithCommon | ICorelatedWithWaitingPeriod;

export interface IFieldHistoryEntry<valueType> {
  userHash: string;
  timestamp: number;
  value: valueType;
}

export interface ICorelatedWithCommonScored {
  code: TAdaCode;
  how: TCorelationHow;
  apply: { value: boolean | null, score?: TScore, changeHistory?: IFieldHistoryEntry<boolean | null>[] };
  data?: { value?: string[], score?: TScore, changeHistory?: IFieldHistoryEntry<string | string[]>[], [key: string]: any };
};

export interface ICorelatedWithWaitingPeriodScored {
  code: TAdaCode;
  how: CorrelationEnum.WAITING_PERIOD;
  apply: { value: boolean | null, score?: TScore, changeHistory?: IFieldHistoryEntry<boolean | null>[] };
  data?: { value: IWaitingPeriod, score?: TScore, changeHistory?: IFieldHistoryEntry<IWaitingPeriod>[] };
};

export type ICorelatedWithScored = ICorelatedWithCommonScored | ICorelatedWithWaitingPeriodScored;

export interface IAdaSimpleObjectScored {
  code: TAdaCode;
  category: string;
  wholeCategory?: { value: boolean, score?: TScore, changeHistory?: IFieldHistoryEntry<boolean>[]};
  appliesToAnnualMax?: { value: boolean, score?: TScore, changeHistory?: IFieldHistoryEntry<boolean>[]};
  annualMax?: { value: number, score?: TScore, changeHistory?: IFieldHistoryEntry<number>[]};
  coinsurance?: { value: number, score?: TScore, changeHistory?: IFieldHistoryEntry<number>[]}; // TODO to delete
  deductible?: { value: number, score?: TScore, changeHistory?: IFieldHistoryEntry<number>[]};
  waitingPeriod?: { value: IWaitingPeriod, score?: TScore, changeHistory?: IFieldHistoryEntry<IWaitingPeriod>[]};
  lifetimeMaximum?: { value: number, score?: TScore, changeHistory?: IFieldHistoryEntry<number>[]};
  limitation?: { value: TLimitation, score?: TScore, changeHistory?: IFieldHistoryEntry<TLimitation>[]};
  caveat?: string[] | string;
  correlatedWith?: ICorelatedWithScored[];
  exceptions?: {[key: string]: IAdaSimpleObjectCustomExceptionScored};
  conflicts?: IAdaSimpleObjectCustomExceptionScored[];
  age?: { value: TAge, score?: TScore, changeHistory?: IFieldHistoryEntry<TAge>[]}; 
}

export type IAdaSimpleObjectException = Partial<IAdaSimpleObject>;

export interface IAdaSimpleObject {
  code: TAdaCode;
  category: string;
  /** deductible for current procedure code, category or subcategory */
  deductible?: number;
  coinsurance?: number; // TODO to delete
  /** Limitation object - specifies how many times a procedure can be performed within a given time unit (e.g., twice per 6 months). */
  limitation?: TLimitation;
  waitingPeriod?: IWaitingPeriod;
  wholeCategory?: boolean;
  appliesToAnnualMax?:boolean;
  annualMax?: number;
  /** Maximum amount that an insurance carrier can spend over the lifetime of a patient for the current procedure. */
  lifetimeMaximum?: number;
  caveat?: string[] | string;
  correlatedWith?: ICorelatedWith[];
  exceptions?: {[key: string]: IAdaSimpleObjectException};
  conflicts?: IAdaSimpleObjectException[];
  age?: TAge;
}

export interface IBreakdowns {
  /** missing tooth clause */
  missingToothClause?: boolean;
  /** annual max for whole plan */
  annualMax?: number;
  /** deductible for whole plan separated for subscriber and his family */
  deductible: {
    individual?: number;
    family?: number;
  };
  adaCodes: { [key in TAdaCode]?: IAdaSimpleObject };
}

export type FlagValue = boolean;

export enum FlagName {
  STRIPE = 'stripe-payments',
  STRIPE_ONE_TIME_PAYMENT = 'stripe-one-time-payment',
  ADAPTERS_3RD_PARTY = 'adapters-3rd-party',
  ANNOUNCEKIT_URL = 'ANNOUNCEKIT_URL',
  STRIPE_KEY = 'STRIPE_KEY',
  MAINTANANCE_LOCK = 'MAINTANANCE_LOCK',
  DOMAIN_ENV_MAP = 'DOMAIN_ENV_MAP',
  PAST_VERRIFICATIONS_ENABLED = 'PAST_VERRIFICATIONS_ENABLED',
  FD_NEW_TYPE = 'FD_NEW_TYPE',
  SCORE_DOWN_ON_PERIOD_BREAK = 'SCORE_DOWN_ON_PERIOD_BREAK',
  USER_GUIDE_PDF = 'USER_GUIDE_PDF',
  ARCHIVISATION_DELAY = 'ARCHIVISATION_DELAY',
  MAXIMUM_FREEMIUM_USERS = 'MAXIMUM_FREEMIUM_USERS',
  TEST_PLANS_ON_SCORE_DROP = 'TEST_PLANS_ON_SCORE_DROP'
}

export enum FlagType {
  NONE = 'none',
  STRING = 'string',
  OBJECT = 'object',
  INTEGER = 'integer'
}

export interface IFeatureFlag {
  title: string;
  name: FlagName;
  description?: string;
  isActive: FlagValue;
  type: FlagType;
  value?: string;
}

export interface IBreakdownsScored {
  missingToothClause?: {
    value: boolean;
    score?: TScore;
    changeHistory?: IFieldHistoryEntry<boolean>[];
  };
  annualMax?: {
    value: number;
    score?: TScore;
    changeHistory?: IFieldHistoryEntry<number>[];
  };
  deductible: {
    individual?: {
      value: number;
      score?: TScore;
      changeHistory?: IFieldHistoryEntry<number>[];
    };
    family?: {
      value: number;
      score?: TScore;
      changeHistory?: IFieldHistoryEntry<number>[];
    };
  };
  adaCodes: { [key in TAdaCode]?: IAdaSimpleObjectScored };
}

export const CategoryArray = [
  'Diagnostic',
  'Preventative',
  'Restorative', 
  'Endodontics',
  'Periodontics', 
  'Periodontics, removable',
  'Maxillofacial Prosthetics',
  'Implant Services',
  'Prosthodontics, fixed',
  'Oral & Maxillofacial Surgery',
  'Orthodontics',
  'Adjunctive General Services'
];

export type TCategory = typeof CategoryArray[number];

export const AdaCodeArray = [
  'D0120',
  'D0140',
  'D0150',
  'D0160',
  'D0170',
  'D6530',
  'D0180',
  'D0210',
  'D0220',
  'D0230',
  'D0240',
  'D0250',
  'D0260',
  'D0270',
  'D0272',
  'D0274',
  'D0277',
  'D0290',
  'D0310',
  'D0320',
  'D0321',
  'D0330',
  'D0340',
  'D0350',
  'D0372',
  'D0373',
  'D0374',
  'D0387',
  'D0388',
  'D0415',
  'D0425',
  'D0460',
  'D0470',
  'D0472',
  'D0473',
  'D6520',
  'D0474',
  'D7942',
  'D0480',
  'D7130',
  'D0502',
  'D0999',
  'D1110',
  'D1120',
  'D1201',
  'D1203',
  'D1204',
  'D1205',
  'D1310',
  'D1320',
  'D1330',
  'D1351',
  'D1510',
  'D1515',
  'D1520',
  'D1525',
  'D1550',
  'D2140',
  'D2150',
  'D2160',
  'D2161',
  'D2330',
  'D2331',
  'D2332',
  'D2335',
  'D2390',
  'D2391',
  'D2392',
  'D2393',
  'D2394',
  'D2410',
  'D2420',
  'D2430',
  'D2510',
  'D2520',
  'D2530',
  'D2542',
  'D2543',
  'D2544',
  'D2610',
  'D2620',
  'D2630',
  'D2642',
  'D2643',
  'D2644',
  'D2650',
  'D2651',
  'D2652',
  'D2662',
  'D2663',
  'D2664',
  'D2710',
  'D2720',
  'D2721',
  'D2722',
  'D2740',
  'D2750',
  'D2751',
  'D2752',
  'D2780',
  'D2781',
  'D2782',
  'D2783',
  'D2790',
  'D2791',
  'D2792',
  'D2799',
  'D2910',
  'D2920',
  'D2930',
  'D2931',
  'D2932',
  'D2933',
  'D2940',
  'D2950',
  'D2951',
  'D2952',
  'D2953',
  'D2954',
  'D2955',
  'D2957',
  'D2960',
  'D2961',
  'D2962',
  'D2970',
  'D2980',
  'D2991',
  'D2999',
  'D3110',
  'D3120',
  'D3220',
  'D3221',
  'D3230',
  'D7430',
  'D3240',
  'D3310',
  'D3320',
  'D3330',
  'D3331',
  'D3332',
  'D9240',
  'D3233',
  'D3346',
  'D3347',
  'D3348',
  'D3351',
  'D3352',
  'D7470',
  'D3353',
  'D7431',
  'D3410',
  'D3421',
  'D3425',
  'D3426',
  'D3430',
  'D3450',
  'D3460',
  'D3470',
  'D3910',
  'D3920',
  'D3950',
  'D3999',
  'D4210',
  'D4211',
  'D4240',
  'D4241',
  'D4245',
  'D4249',
  'D4250',
  'D4260',
  'D4261',
  'D4220',
  'D4263',
  'D4264',
  'D4265',
  'D4266',
  'D4267',
  'D7420',
  'D4268',
  'D4270',
  'D4271',
  'D4273',
  'D4274',
  'D4275',
  'D4276',
  'D4320',
  'D4321',
  'D4341',
  'D4342',
  'D4355',
  'D4381',
  'D6543',
  'D4910',
  'D4920',
  'D4999',
  'D5110',
  'D5120',
  'D5130',
  'D5140',
  'D5211',
  'D5212',
  'D5213',
  'D5214',
  'D5281',
  'D5410',
  'D5411',
  'D5421',
  'D5422',
  'D5510',
  'D5520',
  'D5610',
  'D5620',
  'D5630',
  'D5640',
  'D5650',
  'D5660',
  'D5670',
  'D5671',
  'D5710',
  'D5711',
  'D5720',
  'D5721',
  'D5730',
  'D5731',
  'D5740',
  'D5741',
  'D5750',
  'D5751',
  'D5760',
  'D5761',
  'D5810',
  'D5811',
  'D5820',
  'D5821',
  'D5850',
  'D5851',
  'D5860',
  'D5861',
  'D5862',
  'D5867',
  'D5875',
  'D5899',
  'D5911',
  'D5912',
  'D5913',
  'D5914',
  'D5915',
  'D5916',
  'D5919',
  'D5922',
  'D5923',
  'D5924',
  'D5925',
  'D5926',
  'D5927',
  'D5928',
  'D5929',
  'D5931',
  'D5932',
  'D5933',
  'D5934',
  'D5935',
  'D5936',
  'D5937',
  'D5951',
  'D5952',
  'D5953',
  'D5954',
  'D5955',
  'D5958',
  'D5959',
  'D5960',
  'D5982',
  'D5983',
  'D5984',
  'D5985',
  'D5986',
  'D5987',
  'D5988',
  'D5999',
  'D6010',
  'D6020',
  'D6040',
  'D6050',
  'D6053',
  'D3960',
  'D6054',
  'D6055',
  'D6056',
  'D6057',
  'D6058',
  'D6059',
  'D6060',
  'D6061',
  'D6062',
  'D6063',
  'D6064',
  'D6065',
  'D6066',
  'D7120',
  'D6067',
  'D6068',
  'D6069',
  'D6070',
  'D6071',
  'D6072',
  'D6073',
  'D2810',
  'D6074',
  'D6075',
  'D6076',
  'D6077',
  'D6078',
  'D6079',
  'D6080',
  'D6090',
  'D6095',
  'D6100',
  'D6199',
  'D6210',
  'D6211',
  'D6212',
  'D6240',
  'D6241',
  'D6242',
  'D6245',
  'D6250',
  'D6251',
  'D6252',
  'D6253',
  'D2210',
  'D6545',
  'D6548',
  'D6600',
  'D6601',
  'D6602',
  'D6603',
  'D6604',
  'D6605',
  'D6606',
  'D6607',
  'D6608',
  'D6609',
  'D6610',
  'D6611',
  'D6612',
  'D6613',
  'D6614',
  'D6615',
  'D6720',
  'D6721',
  'D6722',
  'D6740',
  'D6750',
  'D6751',
  'D6752',
  'D6780',
  'D6781',
  'D6782',
  'D6783',
  'D6790',
  'D6791',
  'D6792',
  'D6793',
  'D0501',
  'D6920',
  'D6930',
  'D6940',
  'D6950',
  'D6970',
  'D6971',
  'D6972',
  'D6973',
  'D6975',
  'D6976',
  'D6977',
  'D6980',
  'D6985',
  'D6999',
  'D7111',
  'D7140',
  'D7210',
  'D7220',
  'D7230',
  'D7240',
  'D7241',
  'D7250',
  'D7260',
  'D7261',
  'D7270',
  'D2336',
  'D7272',
  'D7280',
  'D7283',
  'D7281',
  'D7282',
  'D7285',
  'D7286',
  'D7287',
  'D7290',
  'D7291',
  'D7310',
  'D2380',
  'D7320',
  'D7340',
  'D7350',
  'D2382',
  'D7410',
  'D7411',
  'D7412',
  'D7413',
  'D7414',
  'D7415',
  'D7465',
  'D7440',
  'D7441',
  'D7450',
  'D7451',
  'D7460',
  'D7461',
  'D7471',
  'D7472',
  'D7473',
  'D7485',
  'D7490',
  'D7510',
  'D7520',
  'D7530',
  'D7540',
  'D7550',
  'D7560',
  'D7610',
  'D7620',
  'D7630',
  'D7640',
  'D7650',
  'D7660',
  'D7670',
  'D7671',
  'D7680',
  'D7710',
  'D7720',
  'D7730',
  'D7740',
  'D7750',
  'D7760',
  'D7770',
  'D7771',
  'D7780',
  'D7810',
  'D7820',
  'D7830',
  'D7840',
  'D7850',
  'D7852',
  'D7854',
  'D7856',
  'D7858',
  'D7860',
  'D7865',
  'D7870',
  'D7871',
  'D7872',
  'D7873',
  'D7874',
  'D7875',
  'D7876',
  'D7877',
  'D7880',
  'D7899',
  'D7910',
  'D7911',
  'D7912',
  'D7920',
  'D7940',
  'D7941',
  'D7943',
  'D7944',
  'D7945',
  'D7946',
  'D7947',
  'D7948',
  'D6544',
  'D7949',
  'D7950',
  'D7951',
  'D7952',
  'D7955',
  'D7956',
  'D7957',
  'D7960',
  'D7970',
  'D7971',
  'D7972',
  'D7980',
  'D7981',
  'D7982',
  'D7983',
  'D7990',
  'D7991',
  'D7993',
  'D7994',
  'D7995',
  'D7996',
  'D7997',
  'D7999',
  'D8010',
  'D8020',
  'D8030',
  'D8040',
  'D8050',
  'D8060',
  'D8070',
  'D8080',
  'D8090',
  'D8210',
  'D8220',
  'D8660',
  'D8670',
  'D8680',
  'D8690',
  'D8691',
  'D8692',
  'D8999',
  'D9110',
  'D9210',
  'D9211',
  'D9212',
  'D9215',
  'D7480',
  'D9220',
  'D9221',
  'D9230',
  'D9241',
  'D9242',
  'D9248',
  'D9310',
  'D9410',
  'D9420',
  'D9430',
  'D9440',
  'D9450',
  'D9610',
  'D9630',
  'D9910',
  'D9911',
  'D9920',
  'D9930',
  'D9940',
  'D9941',
  'D9947' ,
  'D9950',
  'D9951',
  'D9952',
  'D9970',
  'D9971',
  'D9972',
  'D9973',
  'D9974',
  'D9999',
  'D0145',
  'D0273',
  'D0322',
  'D0360',
  'D0362',
  'D0363',
  'D0416',
  'D0421',
  'D0431',
  'D7110',
  'D0471',
  'D0486',
  'D0475',
  'D0476',
  'D0477',
  'D0478',
  'D0479',
  'D0481',
  'D0482',
  'D0483',
  'D0484',
  'D0485',
  'D1206',
  'D1555',
  'D2712',
  'D2794',
  'D2915',
  'D2934',
  'D2971',
  'D2131',
  'D2975',
  'D3333',
  'D4230',
  'D4231',
  'D5225',
  'D5226',
  'D6190',
  'D6012',
  'D6094',
  'D6194',
  'D6091',
  'D6092',
  'D6093',
  'D6205',
  'D6214',
  'D6624',
  'D6634',
  'D6710',
  'D6794',
  'D7288',
  'D7292',
  'D7293',
  'D7294',
  'D7311',
  'D2130',
  'D7321',
  'D7511',
  'D2120',
  'D7521',
  'D2110',
  'D7953',
  'D7963',
  'D7998',
  'D8693',
  'D9120',
  'D9612',
  'D9942',
  'D1352',
  'D3354',
  'D5992',
  'D5993',
  'D6254',
  'D6795',
  'D7251',
  'D7295',
  'D9985',
  'D9986',
  'D9987',
  'D1208',
  'D2929',
  'D2981',
  'D2983',
  'D2982',
  'D2990',
  'D4212',
  'D3222',
  'D4277',
  'D6051',
  'D6101',
  'D6102',
  'D6103',
  'D6104',
  'D9975',
  'D0190',
  'D0191',
  'D9996',
  'D7952',
  'D0364',
  'D0365',
  'D0366',
  'D0367',
  'D0368',
  'D0369',
  'D0370',
  'D0371',
  'D0380',
  'D0381',
  'D0382',
  'D0383',
  'D0384',
  'D0385',
  'D0386',
  'D0391',
  'D4278',
  'D7921',
  'D0417',
  'D0418',
  'D5991',
  'D6114',
  'D0171',
  'D0251',
  'D0351',
  'D0393',
  'D0394',
  'D0395',
  'D0414',
  'D0422',
  'D0423',
  'D0600',
  'D0601',
  'D0602',
  'D0603',
  'D1353',
  'D1354',
  'D1575',
  'D1999',
  'D2921',
  'D2941',
  'D2949',
  'D3355',
  'D3356',
  'D3357',
  'D3427',
  'D3428',
  'D3429',
  'D3431',
  'D3432',
  'D4283',
  'D4285',
  'D4346',
  'D4921',
  'D5221',
  'D5222',
  'D5223',
  'D5224',
  'D5863',
  'D5864',
  'D5865',
  'D5866',
  'D5994',
  'D6011',
  'D6013',
  'D6052',
  'D6081',
  'D6085',
  'D6111',
  'D6112',
  'D6113',
  'D6116',
  'D6117',
  'D6549',
  'D7881',
  'D8681',
  'D8694',
  'D9219',
  'D9223',
  'D9243',
  'D9311',
  'D9932',
  'D9933',
  'D9934',
  'D9935',
  'D9943',
  'D9991',
  'D9992',
  'D9993',
  'D9994',
  'D9931',
  'D6110',
  'D6115',
  'D3138',
  'D8001',
  'D1315',
  'D5511',
  'D5512',
  'D5611',
  'D5612',
  'D5621',
  'D0411',
  'D5622',
  'D6096',
  'D6118',
  'D6119',
  'D7296',
  'D7297',
  'D7979',
  'D8695',
  'D9222',
  'D9239',
  'D9995',
  'D0412',
  'D1516',
  'D1517',
  'D1526',
  'D1527',
  'D5282',
  'D5283',
  'D5876',
  'D9130',
  'D9613',
  'D9944',
  'D9945',
  'D9946',
  'D9961',
  'D9990',
  //"DP123",
  'D2222',
  'D0419',
  'D1551',
  'D1552',
  'D1553',
  'D1556',
  'D1557',
  'D1558',
  'D2753',
  'D5284',
  'D5286',
  'D6082',
  'D6083',
  'D6084',
  'D6086',
  'D6087',
  'D6088',
  'D6097',
  'D6098',
  'D6099',
  'D6120',
  'D6121',
  'D6122',
  'D6123',
  'D6195',
  'D6243',
  'D6753',
  'D6784',
  'D7922',
  'D8696',
  'D8697',
  'D8698',
  'D8699',
  'D8701',
  'D8702',
  'D8703',
  'D8704',
  'D9997',
  'D0604',
  'D0605',
  'D0701',
  'D0702',
  'D0703',
  'D0704',
  'D0705',
  'D0706',
  'D0707',
  'D0708',
  'D0709',
  'D1321',
  'D1355',
  'D2928',
  'D3471',
  'D3472',
  'D3473',
  'D3501',
  'D3502',
  'D3503',
  'D5995',
  'D5996',
  'D6191',
  'D6192',
  'D7961',
  'D7962',
  'D5900',
  'NONE',
] as const;

export type TAdaCode = typeof AdaCodeArray[number];

export const SponsorTypeArray = [
  "Individual",
  "Employer",
  "Government"
] as const;

export type TSponsorType = typeof SponsorTypeArray[number];

export const TTimePeriodArray = [ 'mo' , 'yr' , '12m' , '/yr', 'd', '/d' ] as const ;
export type TTimePeriod = typeof TTimePeriodArray[number];

export const ParticipationLevelArray = [
  'In Network',
  'Out Of Network',
  'DPPO',
  'TOTAL',
  'Advantage',
  'PPO+Premier',
  'Premier Only',
  'Traditional',
  'PPO',
  'Not Sure'
] as const;
export type TParticipationLevel = typeof ParticipationLevelArray[number];

export interface TLimitation {
  quantity: number;
  frequency: number;
  timePeriod: TTimePeriod;
};

export interface IPayer {
  /** name of payer */
  name: string;
  /** payer id */
  id?: string;
  /** payer address */
  street1?: string;
  /** payer address line 2 */
  street2?: string;
  /** payer city */
  city?: string;
  /** payer state */
  state?: string;
  /** payer zip code */
  zipCode?: string;
  /** payer phone number */
  phoneNumber?: string;
};

export const TBenefitYearArray = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
] as const;

export type TBenefitYear = typeof TBenefitYearArray[number];


export interface ISponsor {
  sponsorName?: string;
  sponsorType?: TSponsorType;
  groupNumber: string;
  groupName?: string;
};

export interface IDetails {
  /** benefit year of plan - its dental healthcare nomenclature - it contains name of month when plan resets */
  benefitYear?: string;
  /** participation level of plan - with subgroups In/Out network, and for In network it may be: PPO + Premier or DPPO, etc */
  participation: TParticipationLevel | string;
  initiationDate?: number;
}

export interface IDetailsScored {
  benefitYear?: { value: string, score?: TScore, changeHistory?: IFieldHistoryEntry<string>[]};
  participation: TParticipationLevel | string;
  initiationDate?: number;
}

export type TPlanScore = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export interface ISchemaPlan {
  benefits: IBreakdowns | IBreakdownsScored;
  caveat?: string[];
  payer: IPayer;
  /** Sponsor data. Contains groupNumber and groupName*/
  sponsor: ISponsor;
  /** @deprecated General plan score - not used any more */
  score?: TPlanScore;
  details: IDetails | IDetailsScored;
  /** Hashes of users that contributed in plan building */
  userHashes?: string[];
  /** General plan hash - checksum of plan object */
  planHash?: string;
  lastSubmition?: number;
  /** Version of schema - 1, 2 or 3 */
  schemaVersion?: string;
};

export type TScore = 1 | 2 | 3 | 4;

export enum CorrelationEnum {
  IN_CONJUNCTION = 'IN_CONJUNCTION',
  SHARES_FREQUENCY = 'SHARES_FREQUENCY',
  WAITING_PERIOD = 'WAITING_PERIOD',
  SUBSTITUTION = 'SUBSTITUTION',
  APPLIES_TO = 'APPLIES_TO'
};

export type TCorelationHow = `${CorrelationEnum}`;

export enum AgeTypeEnum {
  CHILD = 'child',
  STUDENT = 'student',
  ADULT = 'adult',
};
export type TAgeType = `${AgeTypeEnum}`;

export type TAge = IAgeMinMax | IAgeMin | IAgeMax;
interface IAgeMinMax {
  min?: number;
  max?: number;
};

interface IAgeMin {
  min?: number;
  max?: null;
};

interface IAgeMax {
  min?: null;
  max?: number;
};


export interface IHistory {
  code: TAdaCode;
  date: number;
  notes?: string;
}
export interface IHistoryTeeth extends IHistory {
  tooth?: TTooth[];
};
export interface IHistorySurfaces extends IHistory {
  surfaces?: {[key: TTooth]: TSurface}[];
};
export interface IHistoryTeethRange extends IHistory {
  teethRange?: TTooth[];
};
export interface IHistoryQuadrants extends IHistory {
  quadrants?: TQuadrant[];
}
export interface IHistoryArch extends IHistory {
  arch?: TArch;
};

export const AnteriorTeeth =  
[ '6', '7', '8', '9', '10', '11', '22', '23', '24', '25', '26', '27', 'C', 'D', 'E', 'F', 'G', 'H', 'M', 'N', 'O', 'P', 'Q', 'R'];

export const PosterioiTeeth =  
[ '1','2','3','4','5', '12','13','14','15','16', '17','18','19','20','21', '28','29','30','31','32', 'A', 'B', 'I', 'J','K', 'L','S', 'T'];

export type IHistoryAll = IHistory | IHistoryTeeth | IHistorySurfaces | IHistoryQuadrants | IHistoryArch | IHistoryTeethRange;

export const ArchArray = ['U', 'L'];
export type TArch = typeof ArchArray[number];

export const QuadrantArray = ['UL', 'UR', 'LL', 'LR'];
export type TQuadrant = typeof QuadrantArray[number];

const surfacePostteriorArrayTemp = [ 'M', 'D', 'L', 'O', 'B'];
const surfaceAnteriorArrayTemp = [ 'M', 'D', 'L', 'I', 'F'];

export const SurfacePostteriorArray = combo(surfacePostteriorArrayTemp).map(el => el.join(''));
export const SurfaceAnteriorArray = combo(surfaceAnteriorArrayTemp).map(el => el.join(''));

export type TSurfacePostterior = typeof SurfacePostteriorArray[number];
export type TSurfaceAnterior = typeof SurfaceAnteriorArray[number];

export type TSurface = TSurfacePostterior | TSurfaceAnterior;

export const ToothArray = [...AnteriorTeeth, ...PosterioiTeeth];
export type TTooth = typeof ToothArray[number];

export interface IPatient {
  deductibleRemaining?: number;
  insuranceRemaining?: number;
  effectiveDate?: number;
  note?: string;
  history?: IHistoryAll[];
}

export interface ISchemaAllPlan {
  patient: IPatient;
  plan?: ISchemaPlan | ISchemaPlan[];
  ticket?: ITicket;
  schemaVersion?: string;
}

export enum TicketStatusEnum {
  OPEN = 'Open',
  PENDING = 'Pending',
  RESOLVED = 'Resolved',
  CLOSED = 'Closed',
  ADD_INFO = 'Needs additional Info',
  READY_FOR_CALLS = 'Portal info entered ready for calls',
  READY_FOR_QA = 'Ready for QA',
  QA_WITH_ISSUES = 'QA Complete with issues - resent to agent',
  QA_COMPLETE = 'QA Complete',
  ARCHIVED = 'Archived'
}

export type TTicketStatus = `${TicketStatusEnum}`;

export enum RelationToSubscriberEnum {
  SELF = 'Self',
  SPOUSE = 'Spouse',
  CHILDREN = 'Children',
  DEPENDENT = 'Dependent',
  LIFE_PARTNER = 'LifePartner',
  EMPLOYEE = 'Employee',
  OTHER = 'Other'
}

export type TRelationToSubscriber = `${RelationToSubscriberEnum}`;


export interface IPatientInfo {
  patientFirstName: string;
  patientLastName: string;
  patientDOB: string;
  patientMemberId: string;
  patientAddress?: string;
  patientRelationToSubscriber: TRelationToSubscriber;
  subscriberFirstName?: string;
  subscriberLastName?: string;
  subscriberDOB?: string;
  subscriberMemberId?: string;
}

export interface IClientInfo {
  name?: string;
  primaryDoctorName?: string;
  taxId?: string;
  phone?: string;
  npi?: string;
  address?: string;
  maleAgent?: string;
  femaleAgent?: string;
  providerLastName?: string;
}

export interface IAlertNotes {
  creationDate: number;
  /** content of note */
  alertContent: string;
  /** user who created note - email */
  user: string;
}

export interface ITicket {
  integrationPatientInfo?: IPatientInfo;
  integrationClientInfo?: IClientInfo;
  resolvedDate?: number;
  draft?: ISchemaPlan;
  submitPlanId?: string;
  status?: TTicketStatus;
  verrificPlusInteralId: string;
  clientSpecificPlanId?: string;
  payerAgentName?: string;
  callReference?: string;
  // @deprecated - we are not using anymore alertNotes
  // instead of it use planNotes
  alertNotes?: IAlertNotes[];
  documents?: string[];
  externalUrl?: string;
  integration?: string;
  externalId?: string;
  ticketHash?: string;
  schemaVersion?: string;
  isDirty?: boolean;
  requesterId?: string;
  imported?: boolean;
  ehr?: string;
  ehrVersion?: string;
  pluginName?: string;
  pluginVersion?: string;
  pluginEnvironment?: string;
}

export interface IWaitingPeriod {
  codes?: TAdaCode[];
  months?: number | null; 
  days?: number | null;
  years?: number | null;
  weeks?: number | null;
};


export type TCustomQuestions = {
  [key: string]: { value: string; type: string, changeHistory?: IFieldHistoryEntry<string>[]};
};
