export function getMainDomain() {
  const host = window.location.hostname;
  const parts = host.split('.');

  if (parts.length < 2) {
    return null;
  }

  const tld = parts.pop();
  const sld = parts.pop();

  return sld && tld ? `${sld}.${tld}` : null;
}