export { ValidatorCurrent as Validator, fastest } from './validators';
export { GeneratorCurrent as Generator } from './generators';
export { Current as Interface } from './interfaces';
export { V2 as InterfaceV2 } from './interfaces';
export { CarrierCapabilities, PortalAiSettings } from './interfaces';
export { Migrations, MigratorTypes } from './migrators';
export {
  compare,
  isScoredField,
  recursiveScoredFieldMappingIterator,
  getType,
} from './utils';

/** current Schema version */
export const currentVersion = 3;

export { Auth0Verrific, RolesEnum, TAuthRoles } from './auth/Auth0Verrific';

export * as SharedSchemas from './sharedSchemas';
export * from './schema';
export * from './generics';
export * from './helpers';
export * from './requests';
