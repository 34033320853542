export {};

declare global {
  interface Array<T> {
    isEmpty(): boolean;
  }
  interface String {
    capitalizeFirstLetter(): string;
    capitalizeEachWord(): string;
    priceFormat(): string;
  }
}

Array.prototype.isEmpty = function () {
  return this.length === 0;
};

String.prototype.capitalizeFirstLetter = function () {
  const result = this.charAt(0).toUpperCase() + this.slice(1);
  return result;
};

String.prototype.capitalizeEachWord = function () {
  const result = this.split(' ').map(el => el.capitalizeFirstLetter()).join(' ');
  return result;
};

String.prototype.priceFormat = function () {
  const [integerPart, decimalPart] = this.replace(/[^0-9.]/g, '').split('.');
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  let formattedValue = formattedInteger;
  if (decimalPart !== undefined) {
    formattedValue += '.' + (decimalPart.length === 1 ? decimalPart + '0' : decimalPart.slice(0, 2));
  } else {
    formattedValue += '.00';
  }
  
  return formattedValue;
};
