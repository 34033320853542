import { getMainDomain } from './getMainDomain';

export interface IExpirationTime {
  seconds?: number;
  minutes?: number;
  hours?: number;
  days?: number;
  years?: number;
}

export interface ICookieEntry {
  domain?: string;
  expires?: number;
  name: string;
  path?: string;
  value: string;
}

export const DefaultCookieExpirationTime: IExpirationTime = {
  years: 1,
};

const cookieValueSeparator = /=(.*)/;

export class CookieHelper {
  private static _notifications: Array<{
    key: string;
    cbs: ((newValue: string) => void)[];
  }> = [];

  private static getAllCookies(): ICookieEntry[] {
    return document.cookie.split('; ').map((cookie) => {
      const [name, value] = cookie.split(cookieValueSeparator, 2);
      return {
        name,
        value,
      };
    });
  }

  public static setCustomItem(
    key: string,
    value: string,
    expires = DefaultCookieExpirationTime
  ): void {
    const myDate = this.getExpiresDate(expires);

    document.cookie = `${key}=${value};expires=${myDate};domain=${getMainDomain()};path=/`;
    CookieHelper.notifyOnChange(key, value);
  }

  private static getExpiresDate(expires: IExpirationTime) {
    const myDate = new Date();

    if (expires.seconds) {
      myDate.setSeconds(myDate.getSeconds() + expires.seconds);
    }
    if (expires.minutes) {
      myDate.setMinutes(myDate.getMinutes() + expires.minutes);
    }
    if (expires.hours) {
      myDate.setHours(myDate.getHours() + expires.hours);
    }
    if (expires.days) {
      myDate.setDate(myDate.getDate() + expires.days);
    }
    if (expires.years) {
      myDate.setFullYear(myDate.getFullYear() + expires.years);
    }

    return myDate;
  }

  public static getCustomItem(key: string) {
    return CookieHelper._getMainDomainCookieKey(key);
  }

  private static notifyOnChange(key: string, newValue: string) {
    const cbx = CookieHelper._notifications.find((el) => el.key === key);
    if (cbx) {
      cbx.cbs.forEach((cb) => {
        try {
          cb(newValue);
        } catch (e) {
          console.warn(
            `Cookie change callback for key: ${key} and value: ${newValue} crashed!\nCallback body: ${cb}`
          );
        }
      });
    }
  }

  public static attachNotification(
    key: string,
    cb: (newValue: string) => void
  ) {
    let cbx = CookieHelper._notifications.find((el) => el.key === key);
    if (!cbx) {
      cbx = { key, cbs: [] as ((newValue: string) => void)[] };
      CookieHelper._notifications.push(cbx);
    }
    cbx.cbs.push(cb);
  }

  private static _getMainDomainCookieKey(key: string) {
    const entries: ICookieEntry[] = CookieHelper.getAllCookies();
    const tokenEntry = entries.find((entry) => entry.name === key);
    if (tokenEntry) {
      return tokenEntry.value;
    }
    return null;
  }

  public static deleteCookies(names: string[]) {
    names.forEach((element) => {
      const cookieValue = CookieHelper.getCustomItem(element);
      document.cookie = `${element}=${cookieValue};expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${getMainDomain()};path=/`;
    });
  }
}
